<template>
   <!-- Page Preloder -->
   <div id="preloder">
    <div class="loader"></div>
</div>

<!-- Offcanvas Menu Begin -->
<div class="offcanvas-menu-overlay" id="overlay"></div>
<div class="offcanvas-menu-wrapper" id="canvas">
    <div class="offcanvas__option">
        <div class="offcanvas__links">
            <a href="/signin">Sign in</a>
            <!-- <a href="#">FAQs</a> -->
        </div>
        <!-- <div class="offcanvas__top__hover">
            <span>KSH <i class="arrow_carrot-down"></i></span>
            <ul>
                <li>KSH</li>
                <li>EUR</li>
                <li>USD</li>
            </ul>
        </div> -->
    </div>
    <div class="offcanvas__nav__option">
        <a href="#" class="search-switch"><img src="img/icon/search.png" alt=""></a>
        <a href="/wish-list"><img src="img/icon/heart.png" alt=""></a>
        <a href="/shopping-cart"><img src="img/icon/cart.png" alt=""> <span>0</span></a>
        <div class="price">$0.00</div>
    </div>
   <div id="mobile-menu-wrap"><div class="slicknav_menu"><a href="#" aria-haspopup="true" role="button" tabindex="0" class="slicknav_btn slicknav_collapsed" style="outline: none;"><span class="slicknav_menutxt">MENU</span><span class="slicknav_icon"><span class="slicknav_icon-bar"></span><span class="slicknav_icon-bar"></span><span class="slicknav_icon-bar"></span></span></a><nav class="slicknav_nav slicknav_hidden" aria-hidden="true" role="menu" style="display: none;">
        <ul>
            <li class="active"><a href="/index" role="menuitem">Home</a></li>
            <li><a href="/shop" role="menuitem">Shop</a></li>
            <li class="slicknav_parent">
                <a href="#" role="menuitem" aria-haspopup="true" tabindex="-1" class="slicknav_item slicknav_row" @click="toggleMenubar">
                  About Us
                  <span class="slicknav_arrow">▼</span>
                </a>
                <ul class="dropdown" role="menu" :aria-hidden="!isDropdownVisible" :style="{ display: isDropdownVisible ? 'block' : 'none' }">
                  <li><a href="/about" role="menuitem" tabindex="0">About Us</a></li>
                  <li><a href="/shop-details" role="menuitem" tabindex="0">Shop Details</a></li>
                  <li><a href="/shopping-cart" role="menuitem" tabindex="0">Shopping Cart</a></li>
                  <li><a href="/checkout" role="menuitem" tabindex="0">Check Out</a></li>
                  <li><a href="/categories">Categories</a></li>

                  <!-- <li><a href="/blog-details" role="menuitem" tabindex="0">Blog Details</a></li> -->
                </ul>
              </li>
            <!-- <li><a href="/blog" role="menuitem">Blog</a></li> -->
            <li><a href="/contact" role="menuitem">Contact Us</a></li>
        </ul>
    </nav></div></div>
    <!-- <div class="offcanvas__text">
        <p>Free shipping, 30-day return or refund guarantee.</p>
    </div> -->
</div>
<!-- Offcanvas Menu End -->

<!-- Header Section Begin -->
<header class="header">
    <div class="header__top">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-7">
                    <!-- <div class="header__top__left">
                        <p>Free shipping, 30-day return or refund guarantee.</p>
                    </div> -->
                </div>
                <div class="col-lg-6 col-md-5">
                    <div class="header__top__right">
                        <div class="header__top__links">
                            <a href="/signin">Sign in</a>
                            <!-- <a href="#">FAQs</a> -->
                        </div>
                        <!-- <div class="header__top__hover">
                            <span>KSH <i class="arrow_carrot-down"></i></span>
                            <ul>
                                <li>KSH</li>
                                <li>EUR</li>
                                <li>USD</li>
                            </ul>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3">
                <div class="header__logo">
                    <a href="/index"><img src="img/logo.png" alt=""></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <nav class="header__menu mobile-menu">
                    <ul>
                        <li ><a href="/index">Home</a></li>
                        <li><a href="/shop">Shop</a></li>
                        <li><a href="/about">About Us</a>
                            <ul class="dropdown">
                                <li><a href="/about">About Us</a></li>
                                <li><a href="/shop-details">Shop Details</a></li>
                                <li><a href="/shopping-cart">Shopping Cart</a></li>
                                <li><a href="/checkout">Check Out</a></li>
                                <li><a href="/categories">Categories</a></li>
                                <!-- <li><a href="/blog-details">Blog Details</a></li> -->
                            </ul>
                        </li>
                        <!-- <li><a href="/blog">Blog</a></li> -->
                        <li><a href="/contact">Contact Us</a></li>
                    </ul>
                </nav>
            </div>
            <div class="col-lg-3 col-md-3">
                <div class="header__nav__option">
                    <a href="#" class="search-switch"><img src="img/icon/search.png" alt=""></a>
                    <a href="/wish-list"><img src="img/icon/heart.png" alt=""></a>
                    <a href="/shopping-cart"><img src="img/icon/cart.png" alt=""> <span></span></a>
                    <a href="/shopping-cart"><div class="price">KSH {{ cartTotal.toFixed(2) }}</div></a>
                </div>
            </div>
        </div>
        <div class="canvas__open" @click="toggleMenu" ><i class="fa fa-bars"></i></div>
    </div>
</header>
<!-- Header Section End -->

</template>

<script>
import Cookies from "js-cookie";

// Get all list items under the mobile-menu
const listItems = document.querySelectorAll('.mobile-menu li');

// Add click event listeners to all list items
listItems.forEach(item => {
  item.addEventListener('click', function() {
    // Remove the active class from all list items
    listItems.forEach(li => li.classList.remove('active'));

    // Add the active class to the clicked list item
    this.classList.add('active');
  });
});
export default {
  name: "Topbar",
  data() {
    return {
      isDropdownVisible: false // Initially hide the dropdown menu
    };
  },
  props: {
    cartTotal: {
      type: Number,
      default: 0
    }
  },
  methods: {
    toggleMenubar() {
      this.isDropdownVisible = !this.isDropdownVisible; // Toggle the dropdown visibility
    },
    toggleMenu() {
      // Toggle the visibility of the off-canvas menu
      const canvas = document.getElementById("canvas");
      const overlay = document.getElementById("overlay");
      const menuWrapper = document.querySelector(".offcanvas-menu-wrapper");
      const menuOveraly = document.querySelector(".offcanvas-menu-overlay");

      if (overlay.classList.contains("active")) {
        canvas.classList.remove("active");
        overlay.classList.remove("active");
        menuWrapper.classList.remove("active");
        menuOveraly.classList.remove("active");
      } else {
        canvas.classList.add("active");
        menuWrapper.classList.add("active");
        overlay.classList.add("active");
        menuOveraly.classList.add("active");
      }
      menuOveraly.addEventListener("click", function() {
  canvas.classList.remove("active");
  overlay.classList.remove("active");
})
    }
  }
  
};
</script>
