import api from "./api";

class UtilsService {
  /* sue-ecommerce */
 
  getuserdetails(userId) {
    return api.get(`/users/${userId}`);
  }
  getAuthUser() {
    return api.get(`/secure-data`);
  }
  getRoles(){
    return api.get(`/roles`);
  }
  grtToken(user) {
    return api.post(`/login`, user);
  }

  getContactUs() {
    return api.get(`/contact-us`);
  }
  getTerms() {
    return api.get(`/terms`);
  }
  getPrivacy() {
    return api.get(`/privacy`);
  }
  getFaq() {
    return api.get(`/faq`);
  }
  //cart
  getCart() {
    return api.get(`/cart`);
  }
  addToCart(product) {
    return api.post(`/cart`, product);
  }
  removeFromCart(productId) {
    return api.delete(`/cart/${productId}`);
  }
  updateCart(product) {
    return api.put(`/cart`, product);
  }

  //wishlist
  getWishlist() {
    return api.get(`/wishlist`);
  }
  addToWishlist(product) {
    return api.post(`/wishlist`, product);
  }
  removeFromWishlist(productId) {
    return api.delete(`/wishlist/${productId}`);
  }

  //address
  getAddresses() {
    return api.get(`/addresses`);
  }
  addAddress(address) {
    return api.post(`/addresses`, address);
  }
  updateAddress(address) {
    return api.put(`/addresses`, address);
  }
  deleteAddress(addressId) {
    return api.delete(`/addresses/${addressId}`);
  }

  //cards
  getCards() {
    return api.get(`/cards`);
  }
  addCard(card) {
    return api.post(`/cards`, card);
  }
  updateCard(card) {
    return api.put(`/cards`, card);
  }
  deleteCard(cardId) {
    return api.delete(`/cards/${cardId}`);
  }

  //orders
  getOrders() {
    return api.get(`/orders`);
  }
  getOrder(orderId) {
    return api.get(`/orders/${orderId}`);
  }
  createOrder(order) {
    return api.post(`/orders`, order);
  }
  updateOrder(order) {
    return api.put(`/orders`, order);
  }
  deleteOrder(orderId) {
    return api.delete(`/orders/${orderId}`);
  }

  //notifications
  getNotifications() {
    return api.get(`/notifications`);
  }
  getNotification(notificationId) {
    return api.get(`/notifications/${notificationId}`);
  }
  createNotification(notification) {
    return api.post(`/notifications`, notification);
  }
  updateNotification(notification) {
    return api.put(`/notifications`, notification);
  }
  deleteNotification(notificationId) {
    return api.delete(`/notifications/${notificationId}`);
  }


  //messages
  getMessages() {
    return api.get(`/messages`);
  }
  getMessage(messageId) {
    return api.get(`/messages/${messageId}`);
  }
  createMessage(message) {
    return api.post(`/messages`, message);
  }
  updateMessage(message) {
    return api.put(`/messages`, message);
  }
  deleteMessage(messageId) {
    return api.delete(`/messages/${messageId}`);
  }

  //chats
  getChats() {
    return api.get(`/chats`);
  }
  getChat(chatId) {
    return api.get(`/chats/${chatId}`);
  }
  createChat(chat) {
    return api.post(`/chats`, chat);
  }
  updateChat(chat) {
    return api.put(`/chats`, chat);
  }
  deleteChat(chatId) {
    return api.delete(`/chats/${chatId}`);
  }

  //groups
  getGroups() {
    return api.get(`/groups`);
  }
  getGroup(groupId) {
    return api.get(`/groups/${groupId}`);
  }
  createGroup(group) {
    return api.post(`/groups`, group);
  }
  updateGroup(group) {
    return api.put(`/groups`, group);
  }
  deleteGroup(groupId) {
    return api.delete(`/groups/${groupId}`);
  }

  //posts
  getPosts() {
    return api.get(`/posts`);
  }
  getPost(postId) {
    return api.get(`/posts/${postId}`);
  }
  createPost(post) {
    return api.post(`/posts`, post);
  }
  updatePost(post) {
    return api.put(`/posts`, post);
  }
  deletePost(postId) {
    return api.delete(`/posts/${postId}`);
  }

  //comments
  getComments() {
    return api.get(`/comments`);
  }
  getComment(commentId) {
    return api.get(`/comments/${commentId}`);
  }
  createComment(comment) {
    return api.post(`/comments`, comment);
  }
  updateComment(comment) {
    return api.put(`/comments`, comment);
  }
  deleteComment(commentId) {
    return api.delete(`/comments/${commentId}`);
  } 

  //roles
  getRoles() {
    return api.get(`/roles`);
  }
  getRole(roleId) {
    return api.get(`/roles/${roleId}`);
  }
  createRole(role) {
    return api.post(`/roles`, role);
  }
  updateRole(role) {
    return api.put(`/roles`, role);
  }
  deleteRole(roleId) {
    return api.delete(`/roles/${roleId}`);
  }


  //permissions
  getPermissions() {
    return api.get(`/permissions`);
  }
  getPermission(permissionId) {
    return api.get(`/permissions/${permissionId}`);
  }
  createPermission(permission) {
    return api.post(`/permissions`, permission);
  }
  updatePermission(permission) {
    return api.put(`/permissions`, permission);
  }
  deletePermission(permissionId) {
    return api.delete(`/permissions/${permissionId}`);
  }

  //products
  getProducts() {
    return api.get(`/products`);
  }
  getProduct(productId) {
    return api.get(`/products/${productId}`);
  }
  createProduct(product) {
    return api.post(`/products`, product);
  }
  updateProduct(product) {
    return api.put(`/products`, product);
  }
  deleteProduct(productId) {
    return api.delete(`/products/${productId}`);
  }

  //categories
  getCategories() {
    return api.get(`/categories`);
  }
  getCategory(categoryId) {
    return api.get(`/categories/${categoryId}`);
  }
  createCategory(category) {
    return api.post(`/categories`, category);
  }
  updateCategory(category) {
    return api.put(`/categories`, category);
  }
  deleteCategory(categoryId) {
    return api.delete(`/categories/${categoryId}`);
  }

  //sale-categories
  getSaleCategories() {
    return api.get(`/sale-categories`);
  }
  getSaleCategory(saleCategoryId) {
    return api.get(`/sale-categories/${saleCategoryId}`);
  }
  createSaleCategory(saleCategory) {
    return api.post(`/sale-categories`, saleCategory);
  }
  updateSaleCategory(saleCategory) {
    return api.put(`/sale-categories`, saleCategory);
  }
  deleteSaleCategory(saleCategoryId) {
    return api.delete(`/sale-categories/${saleCategoryId}`);
  }

  //sales
  getSales() {
    return api.get(`/sales`);
  }
  getSale(saleId) {
    return api.get(`/sales/${saleId}`);
  }
  createSale(sale) {
    return api.post(`/sales`, sale);
  }
  updateSale(sale) {
    return api.put(`/sales`, sale);
  }
  deleteSale(saleId) {
    return api.delete(`/sales/${saleId}`);
  }

  //reviews
  getReviews() {
    return api.get(`/reviews`);
  }
  addReview(review) {
    return api.post(`/reviews`, review);
  }
  createReview(review) {
    return api.post(`/reviews`, review);
  }
  updateReview(review) {
    return api.put(`/reviews`, review);
  }
  deleteReview(reviewId) {
    return api.delete(`/reviews/${reviewId}`);
  }


  //about-us
  getAboutUs(){
    return api.get(`/about-us`);
  }

  //brands
  getBrands(){
    return api.get(`/brands`);
  }
  getBrand(brandId){
    return api.get(`/brands/${brandId}`);
  }
  createBrand(brand){
    return api.post(`/brands`, brand);
  }
  updateBrand(brand){
    return api.put(`/brands`, brand);
  }
  deleteBrand(brandId){
    return api.delete(`/brands/${brandId}`);
  }

  //partners
  getpartners(){
    return api.get(`/partners`);
  }
  getPartner(partnerId){
    return api.get(`/partners/${partnerId}`);
  }
  createPartner(partner){
    return api.post(`/partners`, partner);
  }
  updatePartner(partner){
    return api.put(`/partners`, partner);
  }
  deletePartner(partnerId){
    return api.delete(`/partners/${partnerId}`);
  }


  //sizes
  getSizes(){
    return api.get(`/sizes`);
  }
  getSize(sizeId){
    return api.get(`/sizes/${sizeId}`);
  }
  createSize(size){
    return api.post(`/sizes`, size);
  }
  updateSize(size){
    return api.put(`/sizes`, size);
  }
  deleteSize(sizeId){
    return api.delete(`/sizes/${sizeId}`);
  }


  //colors
  getColors(){
    return api.get(`/colors`);
  }
  getColor(colorId){
    return api.get(`/colors/${colorId}`);
  }
  createColor(color){
    return api.post(`/colors`, color);
  }
  updateColor(color){
    return api.put(`/colors`, color);
  }
  deleteColor(colorId){
    return api.delete(`/colors/${colorId}`);
  }

  //teams
  getTeams(){
    return api.get(`/teams`);
  }
  getTeam(teamId){
    return api.get(`/teams/${teamId}`);
  }
  createTeam(team){
    return api.post(`/teams`, team);
  }
  updateTeam(team){
    return api.put(`/teams`, team);
  }
  deleteTeam(teamId){
    return api.delete(`/teams/${teamId}`);
  }

  //testimonials
  getTestimonials(){
    return api.get(`/testimonials`);
  }
  getTestimonial(testimonialId){
    return api.get(`/testimonials/${testimonialId}`);
  }
  createTestimonial(testimonial){
    return api.post(`/testimonials`, testimonial);
  }
  updateTestimonial(testimonial){
    return api.put(`/testimonials`, testimonial);
  }
  deleteTestimonial(testimonialId){
    return api.delete(`/testimonials/${testimonialId}`);
  }

  //tags
  getTags(){
    return api.get(`/tags`);
  }
  getTag(tagId){
    return api.get(`/tags/${tagId}`);
  }
  createTag(tag){
    return api.post(`/tags`, tag);
  }
  updateTag(tag){
    return api.put(`/tags`, tag);
  }
  deleteTag(tagId){
    return api.delete(`/tags/${tagId}`);
  }


  //users
  getUsers(){
    return api.get(`/users`);
  }
  getUser(userId){
    return api.get(`/users/${userId}`);
  }
  createUser(user){
    return api.post(`/users`, user);
  }
  updateUser(user){
    return api.put(`/users`, user);
  }
  deleteUser(userId){
    return api.delete(`/users/${userId}`);
  }

}

export default new UtilsService();
