// import { createApp, provide, h } from "vue";
// import App from "./App.vue";
// import router from "./router";
// import store from "./store";
// import VueSweetalert2 from "vue-sweetalert2";
// import "sweetalert2/dist/sweetalert2.min.css";

// // Importing jQuery globally
// import jQuery from "jquery";
// window.jQuery = jQuery;
// window.$ = jQuery;

// // Importing Bootstrap JS and other dependencies
// // import "@/assets/vendor/jquery/jquery.min.js";
// // import "@/assets/vendor/bootstrap/js/bootstrap.bundle.min.js";
// // import "@/assets/vendor/jquery-easing/jquery.easing.min.js";

// // Importing CSS
// import "@/assets/css/fonts.min.css";

// import setupInterceptors from "./services/setupinterceptors";

// setupInterceptors(store);

// // const authLink = setContext((_, { headers }) => {
// //   const token = localStorage.getItem("token");
// //   return {
// //     headers: {
// //       ...headers,
// //       authorization: token ? `${token}` : null,
// //     },
// //   };
// // });


// createApp(App)
//   .use(router)
//   .use(store)
//   .use(VueSweetalert2)
//   .mount("#app");
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import store from "./store";

// Import jQuery globally
import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;

// Configure Axios
axios.defaults.baseURL = "https://api.suesportswear.com"; // Set your base URL here

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

// Initialize your Vue app
createApp(App)
  .use(router)
  .use(store)
  .use(VueSweetalert2)
  .mount("#app");
