<template>
  <section class="contact spad">
    <div class="container">
      <div class="card o-hidden border-0 shadow-lg my-5">
        <div class="card-body p-0">
      <div class="row">

        <div class="col-lg-6 col-md-6" style="
            background-image: url('https://images.pexels.com/photos/20418499/pexels-photo-20418499/free-photo-of-two-women-in-black-and-white-standing-next-to-each-other.jpeg?auto=compress&cs=tinysrgb&w=1600g');
            background-size: cover;
            background-position: center;
          ">
          <div class="contact__text">
            <div class="section-title">
              <h2>Sue Sportswear</h2>
              <p>Your Comfort, is our goal. Elevate your game with Sue-sportswear. Engineered for champions, designed for style.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="contact__form p-4">
            <div class="text-center">
              <h1 class="h4 text-gray-900 mb-2">Sue-Sportwear Sign Up</h1>
            </div>
            <Form class="user" @submit="handleRegister" :validation-schema="schema" ref="form">
              <div class="mb-3">
                <Field name="name" type="text" class="form-control form-control-user" placeholder="First Name and Second Name" v-model="name" />
                <ErrorMessage name="name" class="text-danger py-3 text-sm" />
              </div>
              <div class="mb-3">
                <Field name="email" type="email" class="form-control form-control-user" placeholder="Email Address" v-model="email" />
                <ErrorMessage name="email" class="text-danger py-3 text-sm" />
              </div>
              <div class="mb-3">
                <Field name="phone" type="text" class="form-control form-control-user" placeholder="Phone Number" v-model="phone" />
                <ErrorMessage name="phone" class="text-danger py-3 text-sm" />
              </div>
              <div class="mb-3">
                <Field name="password" type="password" class="form-control form-control-user" placeholder="Password" v-model="password" />
                <ErrorMessage name="password" class="text-danger py-3 text-sm" />
              </div>
              <div class="mb-3">
                <Field name="confirmPassword" type="password" class="form-control form-control-user" placeholder="Confirm Password" v-model="confirmPassword" />
                <ErrorMessage name="confirmPassword" class="text-danger py-3 text-sm" />
              </div>
              <div class="text-center">
                <div class="form-group py-2">
                  <div v-if="message" class="alert alert-danger text-white" role="alert">{{ message }}</div>
                </div>
                <button class="btn btn-lg btn-info btn-lg w-100 mt-4 mb-0" :disabled="loading">
                  <span>Register</span>
                  <span v-if="loading" class="spinner-border text-white spinner-border-sm ml-2"></span>
                </button>
              </div>
            </Form>
            <div class="text-center">
              <a class="small" href="/signin">Already have an account? Sign In</a>
              <!-- <router-link class="small" to="/signin">Already have an account? Sign In</router-link> -->
            </div>
          </div>
        </div>
        </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { Form, Field, ErrorMessage } from "vee-validate";
import { object, string,ref } from "yup";
import UtilsService from "@/services/utils.service"; // Make sure UtilsService is correctly implemented

export default {
  name: "Register",
  components: { Form, Field, ErrorMessage },
  data() {
    const schema = object().shape({
      name: string().required("Names are required!"),
      email: string().email("Invalid Email!").required("Email is required!"),
      password: string().required("Password is required!").min(6, "Password must be at least 6 characters!"),
      confirmPassword: string()
    .required("Password is required!")
    .min(6, "Password must be at least 6 characters!")
    .oneOf([ref('password'), null], "Passwords must match!"), 
    phone: string().required("Phone Number is required!"),
    });
    return {
      loading: false,
      message: "",
      schema,
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      phone: "",
    };
  },
  methods: {
    async handleRegister(user) {
      // event.preventDefault(); // Manually prevent the default form submission

      this.loading = true;
  this.message = "";

      try {
        // Send login request to get token
        const response = await axios.post("/api/users", {
          email: user.email,
          password: user.password,
          name: user.name,
        phone: user.phone,
        role_id: 1,
        });
        if (response.data) {
          // Use email and password to get token
          const loginResponse = await axios.post("/api/login", {
          email: user.email,
          password: user.password,
        });
          if (loginResponse.data && loginResponse.data.token) {
            
        const userId = loginResponse.data.userId;  
        const token = loginResponse.data.token;  
            localStorage.setItem("token", token);
            const userDetails = await axios.get(`/api/users/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        // Save user details to a service or state
        this.$store.commit("setUser", userDetails.data.data);

        // Redirect to dashboard
        this.$router.push("/").then(() => {
          window.location.reload();
        });
 // Reloads the page forcefully  
      }
        }
      } catch (error) {
        console.error(error); 
        this.message = error.response?.data?.message || "An error occurred";
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
/* Add your scoped styles here */
</style>
